import './App.css';
import React from "react";
import {Route, Switch, Redirect, BrowserRouter} from "react-router-dom";
import {ThemeProvider} from "@material-ui/styles";
import {createTheme} from "@material-ui/core";
import NotFound from "./pages/NotFound";
import Main from "./pages/Main";
import IOS from "./pages/IOS";
import AndroidOpen from "./pages/AndroidOpen";
import WindowsOpen from "./pages/WindowsOpen";

let theme = createTheme({
    palette: {
        primary: {
            main: "#D3D3D3"
        },
        secondary: {
            main: "#ffffff"
        }
    },
    typography: {
        fontFamily: 'Arial',
    },
    overrides: {
        MuiButton: {
            root: {
                width: "100%",
                justifyContent: "start",
                fontFamily: 'IRANYekanXVF',
                padding: "12px 16px !important",
                textTransform: "capitalize"
            },
        },
        MuiDialog: {
            paper: {
                borderRadius: '20px'
            }
        },
        MuiAccordionDetails: {
            root: {
                display: "block"
            }
        },
        MuiAccordion: {
            root: {
                '&:first-child': {
                    borderTopRightRadius: "20px !important",
                    borderTopLeftRadius: "20px !important",
                },
                '&:last-child': {
                    borderBottomRightRadius: "20px !important",
                    borderBottomLeftRadius: "20px !important",
                }
            }
        }
    }
});

function App() {
  return (
      <React.Fragment>
        <BrowserRouter >
          <ThemeProvider theme={theme}>
            {/*<ReactPWAInstallProvider>*/}
            {/*  <PWAInstall/>*/}
            {/*</ReactPWAInstallProvider>*/}
            <Switch>
              {/*<Route exact path="/" render={() => <Redirect to="/app/profile" />} />*/}
              {/*<PublicRoute path="/forgot" component={Forgot} />*/}
              <Route  path="/" component={Main} exact/>
              <Route  path="/ios" component={IOS}/>
              <Route  path="/android-openConnect" component={AndroidOpen}/>
              <Route  path="/windows-openConnect" component={WindowsOpen}/>
              <Route component={NotFound} />
            </Switch>
          </ThemeProvider>
        </BrowserRouter>
      </React.Fragment>
  );
}


const PublicRoute = ({ component, isAuthenticated,  ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Redirect
                        to={{
                            pathname: "/",
                        }}
                    />
                ) : (
                    React.createElement(component, props)
                )
            }
        />
    );
}


export default App;
