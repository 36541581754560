import React from 'react';
import { makeStyles } from "@material-ui/styles";
import globalStyles from "../helpers/GlobalStyles";
import '../App.css';
import logo from '../svgs/darwin.svg';
import i1 from '../images/wopen/1.png';
import i2 from '../images/wopen/2.png';
import i3 from '../images/wopen/3.png';
import i4 from '../images/wopen/4.png';
import i5 from '../images/wopen/5.png';
import i6 from '../images/wopen/6.png';
import i7 from '../images/wopen/7.jpg';
import i8 from '../images/wopen/8.jpg';
import i9 from '../images/wopen/9.png';
import i10 from '../images/wopen/10.png';
import i11 from '../images/wopen/11.png';
import {Fab} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    title: {
        background: "#000 !important",
        color: "#fff !important"
    },
    helpBk: {
        padding: "10px",
        background: "#000",
        "& img": {
            maxWidth: "100%"
        }
    },
    helpImg: {
        marginTop: "10px",
    },
    fabDiv: {
        position: 'fixed',
        bottom: 16,
        right: 16,
        maxWidth: "600px"
    },
    fabStyle: {
        fontFamily: 'IRANYekanXVF'
    },
    helpText: {
        background: "#000 !important",
        color: "#fff !important",
        margin: 0,
        paddingTop: 10
    }
}), {index: 1});

function WindowsOpen() {
    const classes = useStyles();
    const globalClasses = globalStyles();

    return (
        <div className="App" >
            <a className={globalClasses.logoDiv}>
                <img className={globalClasses.logo} src={logo} />
            </a>
            <p className={`${globalClasses.title} ${classes.title}`}>
                راهنمای نصب و راه اندازی نرم افزار OpenConnect برای ویندوز
            </p>
            <p className={classes.helpText}>
                ابتدا نرم افزار را دریافت کنید و سپس آن را نصب کنید.
            </p>
            <div className={classes.helpBk}>
                <img src={i1} />
                <img className={classes.helpImg} src={i2} />
                <img className={classes.helpImg} src={i3} />
                <img className={classes.helpImg} src={i4} />
                <img className={classes.helpImg} src={i5} />
                <img className={classes.helpImg} src={i6} />
                <img className={classes.helpImg} src={i7} />
                <p className={classes.helpText}>
                    ۱ – روی تنظیمات کلیک کنید و آن را باز کنید و new profile را انتخاب کنید
                </p>
                <img className={classes.helpImg} src={i8} />
                <p className={classes.helpText}>
                    ۲ – تیک Customize را بزنید و جلوی Name یک اسم دلخواه وارد کنید و سپس Gateway یکی از سرورها را وارد کنید و سپس گزینه save را بزنید.
                </p>
                <img className={classes.helpImg} src={i9} />
                <p className={classes.helpText}>
                    ۳ – و پس از زدن دکمه save & connect مانند زیر یک پیام می آید که روی گزینه accurate information کلیک کنید.
                </p>
                <img className={classes.helpImg} src={i10} />
                <p className={classes.helpText}>
                    ۴ –  و در آخر روی connect کلیک کنید و نام کاربری و رمز عبور را وارد کنید و مانند زیر متصل می شوید .
                </p>
                <img className={classes.helpImg} src={i11} />
            </div>
            <div className={classes.fabDiv}>
                <Fab className={classes.fabStyle} aria-label='برگشت' color='primary' href="/">
                    {/*<EditIcon />*/}
                    برگشت
                </Fab>
            </div>
        </div>
    );
}

export default WindowsOpen;